import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import SimpleReactLightbox from 'simple-react-lightbox'
import {SRLWrapper} from 'simple-react-lightbox'
import Fade from 'react-reveal/Fade'

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query MyQuery(
      $in: [String] = [
        "orbitrek-swan"
        "gabinet-konsultacji"
        "rollmasazer-2"
        "rower-poziomy"
      ]
    ) {
      allFile(filter: {name: {in: $in}}) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const options = {
    settings: {
      transitionSpeed: 900,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    caption: {
      showCaption: false,
    },
  }

  return (
    <div className='gallery-container'>
      <Fade bottom distance={'5%'}>
        <div className='gallery homepage'>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              {data.allFile.edges.map((image, key) => (
                <a href={image.node.publicURL}>
                  <Img
                    key={key}
                    className='image-item'
                    fluid={image.node.childImageSharp.fluid}
                    alt={image.node.base.split('.')[0]}
                  />
                </a>
              ))}
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      </Fade>
    </div>
  )
}

export default Gallery
