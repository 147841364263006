import * as React from 'react'
import {Link} from 'gatsby'
import Layout from '../components/Layout'
import wlascicielki from '../images/wlascicielki.webp'
import konsultacja from '../images/konsultacja.webp'
import konsultacja2 from '../images/umow-sie-na-wizyte.webp'
import bannerPhoto from '../images/beauty-portrait-mystery-smiling-ginger-woman-with-long-hair-posing-sideways-looking-1000.webp'
import bannerPhotoSmall from '../images/beauty-portrait-mystery-smiling-ginger-woman-with-long-hair-posing-sideways-looking-small.webp'

import SmallGallery from '../components/SmallGallery'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCheck,
    faChild,
    faSmile,
    faSpa,
    faWater,
} from '@fortawesome/free-solid-svg-icons'
import Fade from 'react-reveal/Fade'
import {Seo} from '../components/Seo'

const IndexPage = () => {
    return (
        <>
            <Seo title='Modelowanie Sylwetki' />
            <Layout>
                <div className='page-container'>
                    {/* BANNER */}
                    <div className='banner'>
                        <div className='content-container'>
                            <div className='content'>
                                <Fade>
                                    <h2>Modelowanie Sylwetki</h2>
                                    {/* <img src={logo}></img> */}
                                    <img
                                        className='preloadHidden'
                                        rel='preload'
                                        src={bannerPhoto}
                                    />
                                    <img
                                        className='preloadHidden'
                                        rel='preload'
                                        src={bannerPhotoSmall}
                                    />
                                    <h3>ODWIEDŹ NAS</h3>
                                    <p>
                                        ul. Trakt Lubelski 300B, <br />
                                        04-667 Warszawa
                                    </p>
                                    <h3>GODZINY OTWARCIA</h3>
                                    <p>
                                        PON - PT 9:00-21:00 <br />
                                        SOB 9:00 - 15:00
                                    </p>
                                </Fade>
                                <Fade left>
                                    <div className='buttons'>
                                        <Link to='/kontakt'>
                                            <button className='unfilled'>KONTAKT</button>
                                        </Link>

                                        <Link to='/voucher'>
                                            <button>VOUCHER</button>
                                        </Link>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>

                    {/* TESTIMONIAL */}
                    <Fade bottom distance={'30%'}>
                        <div className='testimonial'>
                            <i class='fas fa-quote-left'></i>
                            <p>
                                Polecam, super zabiegi, fantastyczne miejsce prowadzone
                                profesjonalnie i z pasją przez sympatyczne dziewczyny.
                                Efekty gwarantowane
                            </p>
                            <p>
                                Małgorzata
                                <br />
                            </p>

                            <span>Google</span>
                        </div>
                    </Fade>

                    {/* ABOUT */}
                    <Fade bottom distance={'10%'}>
                        <div className='about-container'>
                            <h2>O Nas</h2>
                            <div className='about'>
                                <div className='col-1'>
                                    <div className='image-container'>
                                        <img
                                            src={wlascicielki}
                                            width='650px'
                                            height='433px'
                                            alt='włascicielki'
                                        ></img>
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className='logo-background-wrap'>
                                        <p>
                                            „Modelowanie Sylwetki” to magiczne miejsce
                                            stworzone z pasji przez dwie wyjątkowe
                                            kobiety. Myślą przewodnią naszego gabinetu
                                            było utworzenie miejsca, gdzie w kameralnej i
                                            przytulnej atmosferze można zadbać o swoje
                                            ciało. Jest to wyjątkowe miejsce dla osób,
                                            które niezależnie od swojego wieku chcą zadbać
                                            o siebie i o swoje ciało. W naszych gabinetach
                                            pozbędziesz się zbędnych kilogramów,
                                            wymodelujesz sylwetkę, poprawisz kondycję
                                            fizyczną oraz pozbędziesz się niechcianego
                                            owłosienia.
                                        </p>
                                    </div>
                                    <p>
                                        Dzięki wiedzy i doświadczeniu naszego zespołu
                                        każda wizyta w gabinecie Modelowania Sylwetki jest
                                        przyjemną i motywującą odskocznia od codzienności
                                        . Miejsce o niepowtarzalnym klimacie i trafioną
                                        odpowiedzią na potrzeby ciała w każdym wieku.
                                    </p>
                                </div>
                            </div>
                            <Fade left delay={300} distance={'30%'}>
                                <Link to='/o-nas'>
                                    <button>Więcej</button>
                                </Link>
                            </Fade>
                        </div>
                    </Fade>

                    <Fade bottom distance={'5%'}>
                        <div className='zabiegi-container'>
                            <div className='zabiegi-inner-container'>
                                <div className='zabieg one'>
                                    <Link to='/zabiegi-na-twarz/sonoforeza'>
                                        <h3>Sonoforeza</h3>
                                        <div className='content'>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Rozświetlona cera </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>
                                                    Dotlenienie, nawilżenie i odżywienie
                                                    skóry{' '}
                                                </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Rozjaśnienie przebarwień </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Poprawa elastyczności skóry </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Redukcja blizn </p>
                                            </div>

                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Spłycenie zmarszczek</p>
                                            </div>
                                            <FontAwesomeIcon icon={faSmile} />
                                        </div>
                                    </Link>
                                </div>

                                <div className='zabieg two'>
                                    <Link to='/zabiegi-na-cialo/oczyszczanie-wodorowe'>
                                        <h3>Oczyszczanie Wodorowe</h3>
                                        <h4>Zabieg ten wzbogacony o:</h4>
                                        <div className='content'>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>
                                                    Infuzję tlenową, która odżywia skórę,
                                                    pozostawia ją nawilżoną i rozświetloną{' '}
                                                </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>
                                                    Ultradźwięki, które pozwalają wniknąć
                                                    głębiej substancjom odżywczym{' '}
                                                </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>
                                                    Falę Radiową, która pobudza włókna
                                                    kolagenowe do pracy, a skóra staje się
                                                    jędrna i elastyczna{' '}
                                                </p>
                                            </div>
                                            <FontAwesomeIcon icon={faWater} />
                                        </div>
                                    </Link>
                                </div>

                                <div className='zabieg three'>
                                    <Link to='/zabiegi-na-cialo/endermomasaz'>
                                        <h3>Endermomasaż</h3>
                                        <div className='content'>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Wymodelowana sylwetka</p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Zejście z obwodów </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Drenaż limfatyczny całego ciała</p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Redukcja cellulitu </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Ujędrnienie skóry </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Relaksacyjny masaż </p>
                                            </div>
                                            <FontAwesomeIcon icon={faSpa} />
                                        </div>
                                    </Link>
                                </div>
                                <div className='zabieg four'>
                                    <Link to='/zabiegi-na-cialo/liposukcja-kawitacyjna'>
                                        <h3>Liposukcja Kawitacyjna</h3>
                                        <div className='content'>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Redukcję tkanki tłuszczowej </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Redukcję cellulitu </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Uelastycznienie skóry </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Poprawę krążenia </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />{' '}
                                                <p>Wspomaganie metabolizmu </p>
                                            </div>
                                            <div className='list-item'>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <p>Redukcję lokalnego otłuszczenia </p>
                                            </div>
                                            <FontAwesomeIcon icon={faChild} />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <div className='page konsultacja'>
                        <Fade bottom distance={'5%'}>
                            <div className='content-1'>
                                <div className='section'>
                                    <div className='text'>
                                        <h1>Konsultacja</h1>

                                        <p>
                                            Jest to około godzinne, niezobowiązujące
                                            spotkanie, podczas którego rozmawiamy z
                                            naszymi Klientami na temat ich stanu zdrowia,
                                            poznajemy ich nawyki żywieniowe oraz
                                            dowiadujemy się jaki prowadzą tryb życia.
                                            Robimy podczas niego dokładne pomiary przy
                                            pomocy analizatora składu i masy ciała, a
                                            następnie omawiamy wyniki.
                                            <br />
                                            <br />
                                            Rozmowa diagnozująca ma na celu wykluczenie
                                            przeciwwskazań do zabiegu oraz dobranie
                                            odpowiedniego pakietu do problemu i oczekiwań
                                            Klienta, tak by był skuteczny, a jednocześnie
                                            bezpieczny.
                                        </p>

                                        <Fade left>
                                            <Link to='/kontakt'>
                                                <button>Umów Wizytę</button>
                                            </Link>
                                        </Fade>
                                    </div>
                                    <div className='image-container'>
                                        <img
                                            src={konsultacja}
                                            loading='lazy'
                                            alt='Zdjęcie konsulatcji'
                                        />
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>

                    {/* TESTIMONIAL */}
                    <Fade bottom distance={'30%'}>
                        <div className='testimonial'>
                            <i class='fas fa-quote-left'></i>
                            <p>Polecam super atmosfera rewelacyjne zabiegi :)</p>

                            <p>Wioleta</p>

                            <span>Google</span>
                        </div>
                    </Fade>
                    <SmallGallery />
                    <Fade left>
                        <Link to='/galeria'>
                            <button>Zobacz Więcej</button>
                        </Link>
                    </Fade>
                </div>
            </Layout>
            <div className='copyright-notice'>
                <a>Banner photo created by drobotdean - www.freepik.com</a>
            </div>
        </>
    )
}

export default IndexPage
